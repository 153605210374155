// // @spellchecker: ignore openai
import { SCMType, UNIVERSAL_SCM_TYPE } from "../../scm-type";
import { CredentialsData } from "./credentials";
import { IntegrationAttributes, type ScmIntegrationAttributes } from "./integration-attributes";

//TODO: get rid of IntegrationType and use SCMType

export type IntegrationSortKeyFields = Pick<Integration, "integrationOrgId" | "integrationType">;
export type IntegrationCategory = "source-control" | "communication" | "agent" | "issues" | "ai";

const scmIntegrationTypes = ["github", "azure-devops", "bitbucket-server", "bitbucket-cloud", "gitlab"] as const;
const oldUniversalScmIntegrationTypes = ["bitbucket-server", "bitbucket-cloud", "gitlab"] as const;
const communicationIntegrationTypes = ["slack", "msteams"] as const;
const issuesIntegrationTypes = ["jira", "azure-devops-boards"] as const;
const agentIntegrationTypes = ["scanner-agent"] as const;
const llmIntegrationTypes = ["openai", "azure-openai"] as const;
const integrationTypes = [...scmIntegrationTypes, ...communicationIntegrationTypes, ...agentIntegrationTypes, ...issuesIntegrationTypes, ...llmIntegrationTypes] as const;

export type CommunicationIntegrationType = (typeof communicationIntegrationTypes)[number];
export type IssuesIntegrationType = (typeof issuesIntegrationTypes)[number];
export type LlmIntegrationType = (typeof llmIntegrationTypes)[number];
export type ScmIntegrationType = (typeof scmIntegrationTypes)[number];
export type OldUniversalScmIntegrationType = (typeof oldUniversalScmIntegrationTypes)[number];
export type UniversalScmIntegrationType = ScmIntegrationType;
export type AgentIntegrationType = (typeof agentIntegrationTypes)[number];
export type IntegrationType = (typeof integrationTypes)[number];

export type TransactionalBotIntegrationType = CommunicationIntegrationType | ScmIntegrationType | IssuesIntegrationType;
export type TransactionalBotIntegration = CommunicationIntegration | ScmIntegration | IssuesIntegration;
export const TransactionalBotIntegrationType = {
  is(type: IntegrationType): type is TransactionalBotIntegrationType {
    return CommunicationIntegrationType.is(type) || ScmIntegrationType.is(type) || IssuesIntegrationType.is(type);
  }
} as const;
export const TransactionalBotIntegration = {
  is(integration: Integration): integration is TransactionalBotIntegration {
    return TransactionalBotIntegrationType.is(integration.integrationType);
  }
} as const;

export type SlackInstallation = {
  bot?: {
    token: string;
    refreshToken?: string;
    expiresAt?: number;
    scopes: string[];
    id: string;
    userId: string;
  };
  [key: string]: any;
};

/**
 * not to be used directly, just to remove the | null from toSCMType
 */
const scmIntegrationTypeToSCMType: Record<ScmIntegrationType, SCMType> = {
  github: SCMType.GITHUB,
  "azure-devops": SCMType.AZURE_DEVOPS,
  "bitbucket-server": SCMType.BITBUCKET_SERVER,
  "bitbucket-cloud": SCMType.BITBUCKET_CLOUD,
  gitlab: SCMType.GITLAB
} as const;

/**
 * @deprecated use ScmIntegrationType.toSCMType or IntegrationType.toSCMType
 */
export const toSCMType: Record<IntegrationType, SCMType | null> = {
  ...scmIntegrationTypeToSCMType,
  slack: null,
  msteams: null,
  "scanner-agent": null,
  jira: null,
  openai: null,
  "azure-openai": null,
  "azure-devops-boards": null
} as const;

/**
 * @deprecated use `IntegrationAttributes.get(scmType).type`
 */
export const toIntegrationType: Record<SCMType, ScmIntegrationType> = {
  [SCMType.GITHUB]: "github",
  [SCMType.AZURE_DEVOPS]: "azure-devops",
  [SCMType.BITBUCKET_SERVER]: "bitbucket-server",
  [SCMType.BITBUCKET_CLOUD]: "bitbucket-cloud",
  [SCMType.GITLAB]: "gitlab"
};

/**
 * @deprecated use `IntegrationAttributes.category` instead
 */
export const toCategory: Record<IntegrationType, IntegrationCategory> = {
  github: IntegrationAttributes["github"].category,
  "azure-devops": IntegrationAttributes["azure-devops"].category,
  "bitbucket-server": IntegrationAttributes["bitbucket-server"].category,
  "bitbucket-cloud": IntegrationAttributes["bitbucket-cloud"].category,
  gitlab: IntegrationAttributes["gitlab"].category,
  slack: IntegrationAttributes["slack"].category,
  msteams: IntegrationAttributes["msteams"].category,
  "scanner-agent": IntegrationAttributes["scanner-agent"].category,
  jira: IntegrationAttributes["jira"].category,
  openai: IntegrationAttributes["openai"].category,
  "azure-openai": IntegrationAttributes["azure-openai"].category,
  "azure-devops-boards": IntegrationAttributes["azure-devops-boards"].category
};

export const IntegrationType = {
  attributes: IntegrationAttributes,
  /**
   * @deprecated use `IntegrationAttributes.scmType` instead
   */
  toSCMType(type: IntegrationType): SCMType | null {
    return (IntegrationAttributes[type] as ScmIntegrationAttributes).scmType || null;
  },
  /**
   * @deprecated use `IntegrationAttributes.scmType` instead
   */
  fromSCMType(scm: SCMType): IntegrationType | null {
    return IntegrationAttributes.get(scm).type || null;
  },
  /**
   * @deprecated use `IntegrationType.is` instead
   */
  isIntegrationType(type: unknown): type is IntegrationType {
    return IntegrationType.is(type);
  },
  is(type: unknown): type is IntegrationType {
    return !!IntegrationAttributes[type as IntegrationType];
  },
  /**
   * @deprecated use `IntegrationAttributes[type].displayName` instead
   */
  getDisplayName(type: IntegrationType): string {
    return IntegrationAttributes[type].displayName;
  },
  /**
   * @deprecated use `IntegrationAttributes.ui.icon` instead
   */
  getIcon(type: IntegrationType): `mdi-${string}` {
    return IntegrationAttributes[type].ui.icon;
  }
} as const;

export const LlmIntegrationType = {
  is(type: unknown): type is LlmIntegrationType {
    return typeof type === "string" && llmIntegrationTypes.includes(type as LlmIntegrationType);
  }
} as const;

export const ScmIntegrationType = {
  values: scmIntegrationTypes,
  /**
   * @deprecated use `IntegrationAttributes[type].scmType` instead
   */
  toSCMType(type: ScmIntegrationType): SCMType {
    return scmIntegrationTypeToSCMType[type];
  },
  /**
   * @deprecated use `IntegrationAttributes.get(scmType).type` instead
   */
  fromSCMType(scm: SCMType): ScmIntegrationType {
    return toIntegrationType[scm];
  },
  /**
   * @obsolete use `IntegrationAttributes[type].project.isSupported` instead
   */
  needsProjectDataParsedSeparately(type: ScmIntegrationType): boolean {
    return IntegrationAttributes[type].project.isSupported;
  },
  is(type: unknown): type is ScmIntegrationType {
    return typeof type === "string" && scmIntegrationTypes.includes(type as ScmIntegrationType);
  },
  getFromIntegrationOrgIdAndType(integrationOrgIdAndType: IntegrationOrgIdAndType): ScmIntegrationType | null {
    const type = integrationOrgIdAndType.split("_").at(-1);
    return type && ScmIntegrationType.is(type) ? type : null;
  },
  all(): ScmIntegrationType[] {
    return [...scmIntegrationTypes];
  }
} as const;

export const UniversalScmIntegrationType = {
  is(type: unknown): type is UniversalScmIntegrationType {
    return ScmIntegrationType.is(type);
  },
  displayNameIsEditableWhenCreatingIntegration(type: UniversalScmIntegrationType): boolean {
    return type === "bitbucket-server";
  },
  some(integrationTypes: unknown[]) {
    return integrationTypes.some((t) => UniversalScmIntegrationType.is(t));
  },
  fromSCMType(scm: UNIVERSAL_SCM_TYPE): UniversalScmIntegrationType {
    const type = IntegrationAttributes.get(scm)?.type;
    if (!UniversalScmIntegrationType.is(type)) {
      throw new Error(`Invalid SCM type: '${scm}'`);
    }
    return type;
  },
  toSCMType(type: UniversalScmIntegrationType): UNIVERSAL_SCM_TYPE {
    if (!UniversalScmIntegrationType.is(type)) {
      throw new Error(`Invalid Universal SCM type: '${type}'`);
    }
    return IntegrationAttributes[type].scmType;
  }
} as const;

export const OldUniversalScmIntegrationType = {
  is(type: unknown): type is OldUniversalScmIntegrationType {
    return typeof type === "string" && oldUniversalScmIntegrationTypes.includes(type as OldUniversalScmIntegrationType);
  }
};

export type IntegrationGitHubMetadata = {
  planName: "enterprise" | "team" | "free" | string | null;
  accountType: "User" | "Organization" | string | null;
};
export type IntegrationGitHubStatistics = {
  lastAuditEventDate?: number;
  lastAuditEventSource?: "upload" | "ingestion" | undefined;
};
export type IntegrationADOMetadata = {
  /**
   * @deprecated webhookAuthKey inside ado-webhooks.service.ts is used instead. Kept for backwards compatibility
   */
  apiKeyHashedAndSalted?: string;
};

export type WebhookSubscription = {
  id: string;
  projectId: string;
};

export type IntegrationSlackMetadata = {
  isEnterpriseInstall: boolean;
  slackUserIdWhoAddedIntegration: string;
};
export interface IntegrationMetadata {
  gitHub?: IntegrationGitHubMetadata;
  slack?: IntegrationSlackMetadata;
  /**
   * @deprecated not used anymore, if you need a place to store, create a prop named `[SCMType.AZURE_DEVOPS]` instead
   */
  ado?: IntegrationADOMetadata;

  /** MSTeams Team ID */
  teamId?: string;
  [key: string]: any;
}

export interface LlmIntegrationMetadata extends IntegrationMetadata {
  ai: {
    availableModels: string[];
    modelsInSelection: string[];
    chosenModel: string;
  };
}

export const LlmIntegrationMetadata = {
  is(metadata: unknown): metadata is LlmIntegrationMetadata {
    return (
      !!metadata &&
      typeof metadata === "object" &&
      "ai" in metadata &&
      typeof metadata.ai === "object" &&
      !!metadata.ai &&
      "availableModels" in metadata.ai &&
      Array.isArray(metadata.ai.availableModels) &&
      "modelsInSelection" in metadata.ai &&
      Array.isArray(metadata.ai.modelsInSelection) &&
      "chosenModel" in metadata.ai &&
      typeof metadata.ai.chosenModel === "string"
    );
  }
} as const;

export type IntegrationStatistics = {
  gitHub?: IntegrationGitHubStatistics;
  [key: string]: any;
};

export enum IntegrationVersion {
  VERSION_2 = 2
}

export type IntegrationOrgIdAndType<T extends IntegrationType = IntegrationType> = `${string}_${T}`;
export const IntegrationOrgIdAndType = {
  is(integrationOrgIdAndType: unknown): integrationOrgIdAndType is IntegrationOrgIdAndType {
    return !!this.tryParse(integrationOrgIdAndType);
  },
  parse<T extends IntegrationType = IntegrationType>(integrationOrgIdAndType: IntegrationOrgIdAndType<T>): { integrationOrgId: string; type: T } {
    const parsed = this.tryParse<T>(integrationOrgIdAndType);
    if (!parsed) {
      throw new Error(`Invalid integrationOrgIdAndType: ${integrationOrgIdAndType}`);
    }
    return parsed;
  },
  tryParse<T extends IntegrationType = IntegrationType>(integrationOrgIdAndType: unknown): { integrationOrgId: string; type: T } | null {
    try {
      if (typeof integrationOrgIdAndType !== "string") {
        return null;
      }

      const lastIndex = integrationOrgIdAndType.lastIndexOf("_");
      if (lastIndex === -1) {
        return null;
      }

      const integrationOrgId = integrationOrgIdAndType.substring(0, lastIndex);
      const type = integrationOrgIdAndType.substring(lastIndex + 1);

      if (!integrationOrgId || !IntegrationType.is(type)) {
        return null;
      }
      return {
        integrationOrgId,
        type: type as T
      };
    } catch {
      return null;
    }
  },
  from<T extends IntegrationType>(integrationOrgId: string, type: T): IntegrationOrgIdAndType<T> {
    return `${integrationOrgId}_${type}`;
  }
} as const;

export interface Integration<T extends IntegrationType = IntegrationType> {
  /**
   * partition key
   */

  arnicaOrgId: string;

  /**
   * sort key
   */
  integrationOrgIdAndType: IntegrationOrgIdAndType<T>;

  integrationOrgId: string;

  displayName?: string;

  //TODO: stop using this, instead rely on baseUrl and make integrationOrgId be always the actual org ID without the prefix.
  /**
   * integrationOrgId may contain the github enterprise endpoint,
   * shortOrgId exists to store the actual org ID without the prefix
   */
  shortOrgId?: string;

  /**
   * Only applicable for on-prem integrations, goes along with isSelfHosted=true, and shortOrgId
   */
  baseUrl?: string;

  //TODO: change to SCMType
  integrationType: T;
  //TODO: make these optional
  refreshToken?: string;
  refreshTokenExp?: number;
  accessToken?: string;
  accessTokenExp?: number;
  installationId?: number;
  repos?: string[];
  /**
   * Ingestor is hosted on-prem
   */
  isSelfHosted: boolean;
  jobSchedule?: IntegrationJobSchedule;
  metadata?: IntegrationMetadata;
  statistics?: IntegrationStatistics;
  version?: IntegrationVersion;

  createdBy: string | undefined;
  createdAt: string | undefined;
  updatedAt?: string;
  updatedBy?: string;

  credentials?: CredentialsData;
}

/**
 * Integrations representing a connection to a source control management system
 */
export type ScmIntegration<S extends ScmIntegrationType = ScmIntegrationType> = Integration<S> & { appId?: string };

export const ScmIntegration = {
  is<S extends ScmIntegrationType = ScmIntegrationType>(integration: Integration, type?: S): integration is ScmIntegration<S> {
    return ScmIntegrationType.is(integration.integrationType) && (!type || integration.integrationType === type);
  }
} as const;

export type ScmIntegrationWithBaseUrl<S extends ScmIntegrationType = ScmIntegrationType> = ScmIntegration<S> & { baseUrl: string };
export const ScmIntegrationWithBaseUrl = {
  from(integration: ScmIntegration): ScmIntegrationWithBaseUrl | null {
    if (integration.baseUrl) {
      return integration as ScmIntegrationWithBaseUrl;
    }
    const defaultBaseUrl = DEFAULT_BASE_URLS[integration.integrationType];
    if (!defaultBaseUrl) {
      return null;
    }
    return { ...integration, baseUrl: defaultBaseUrl };
  }
};

export const DEFAULT_BASE_URLS = {
  github: "https://github.com",
  gitlab: "https://gitlab.com",
  "azure-devops": "https://dev.azure.com",
  "bitbucket-server": null,
  "bitbucket-cloud": "https://bitbucket.org"
} satisfies Record<ScmIntegrationType, string | null>;

/**
 * Integrations representing a connection to a communication platform
 */
export type CommunicationIntegration<T extends CommunicationIntegrationType = CommunicationIntegrationType> = Integration<T> & { integrationType: T };
export type IssuesIntegration<T extends IssuesIntegrationType = IssuesIntegrationType> = Integration<T> & { integrationType: T; baseUrl: string };
export type LlmIntegration<T extends LlmIntegrationType = LlmIntegrationType> = Integration<T> & { integrationType: T };
export type AgentIntegration<T extends AgentIntegrationType = AgentIntegrationType> = Integration<T> & { integrationType: T };

export const CommunicationIntegrationType = {
  is(type: string): type is CommunicationIntegrationType {
    return communicationIntegrationTypes.includes(type as CommunicationIntegrationType);
  }
} as const;

export const CommunicationIntegration = {
  is(integration: Integration): integration is CommunicationIntegration {
    return CommunicationIntegrationType.is(integration.integrationType);
  }
} as const;

export const IssuesIntegration = {
  is(integration: Integration): integration is IssuesIntegration {
    return IssuesIntegrationType.is(integration.integrationType);
  }
} as const;

export const IssuesIntegrationType = {
  is(type: unknown): type is IssuesIntegrationType {
    return issuesIntegrationTypes.includes(type as IssuesIntegrationType);
  }
} as const;

export const LlmIntegration = {
  is(integration: Integration): integration is LlmIntegration {
    return LlmIntegrationType.is(integration.integrationType);
  }
} as const;

export const AgentIntegrationType = {
  is(type: string): type is AgentIntegrationType {
    return agentIntegrationTypes.includes(type as AgentIntegrationType);
  }
} as const;

export interface IntegrationJobSchedule {
  /**
   * Min time between runs in milliseconds
   * NOTE: It measures time since the last complete time (e.g. the last updatedAt time of the job), not since the time the job started
   * NOTE: It adds a random buffer of 1 hour on top of that to avoid a hot-spot
   */
  timeBetweenRunsMillis: number;
  /**
   * Start of daily window relative to midnight UTC
   */
  windowStartUTCMillis: number;
  /**
   * End of daily window relative to midnight UTC
   */
  windowEndUTCMillis: number;
}

export function integrationSortKey(integration: IntegrationSortKeyFields): IntegrationOrgIdAndType;
export function integrationSortKey<T extends IntegrationType = IntegrationType>(integrationOrgId: string, integrationType: T): IntegrationOrgIdAndType<T>;
export function integrationSortKey<T extends IntegrationType = IntegrationType>(
  integrationFieldsOrOrgId: IntegrationSortKeyFields | string,
  integrationType?: T
): IntegrationOrgIdAndType<T> {
  if (typeof integrationFieldsOrOrgId === "string") {
    if (!integrationType) {
      throw new Error("integrationType is required when integrationFieldsOrOrgId is a string");
    }
    return `${integrationFieldsOrOrgId}_${integrationType}`;
  }
  return integrationSortKey<T>(integrationFieldsOrOrgId.integrationOrgId, integrationFieldsOrOrgId.integrationType as T);
}

// TODO just make a single method that returns splits them and return both
export function getIntegrationTypeFromSortKey(integrationOrgIdAndType: string): IntegrationType {
  const splitOnSeparator = integrationOrgIdAndType.split("_");
  const integrationType = splitOnSeparator[splitOnSeparator.length - 1];
  if (!integrationType || !IntegrationType.isIntegrationType(integrationType)) {
    throw new Error("Invalid integrationOrgIdAndType");
  }
  return integrationType as IntegrationType;
}

export function getIntegrationOrgIdFromSortKey(integrationOrgIdAndType: string): string {
  const splitOnSeparator = integrationOrgIdAndType.split("_");
  splitOnSeparator.pop();
  return splitOnSeparator.join("");
}

export const IntegrationCategory = {
  is<T extends IntegrationCategory>(category: T, value: string): value is T {
    return value === category;
  }
} as const;

import { ApiBase } from "./api-base";
import { AxiosRequestConfig } from "axios";
import { ProductFilter, ProductFilterField, ProductSort, UIProduct, UIProductDTO } from "$/ui-models/products-v2/ui-product";

const ENABLE_SEARCH_VERB = !!new URLSearchParams(window.location.search).get("searchVerb");

interface GetProductOptions {
  page: number;
  pageSize: number;
  filter?: ProductFilter;
  sort?: ProductSort;
  search?: string;
  silent?: boolean;
}

class ProductsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "products", name: "ProductsApi" });
  }

  public async getAll({ page, pageSize, filter, sort, search, silent = false }: GetProductOptions): Promise<UIProduct[]> {
    const config: AxiosRequestConfig = {
      method: ENABLE_SEARCH_VERB ? "SEARCH" : "POST",
      silent,
      params: {
        page,
        pageSize
      },
      data: { filter, sort, search }
    };

    const res = await this.client.request<UIProductDTO[]>(config);
    return res.data?.map((d) => new UIProduct(d));
  }

  public async getCounts(filter?: ProductFilter, search?: string, silent = false): Promise<number> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.post<number>("/counts", { filter, search }, config);
    return res.data ?? null;
  }

  public async getFilterCounts(
    filter?: ProductFilter,
    search?: string,
    column?: ProductFilterField,
    silent = false
  ): Promise<PartialRecord<keyof ProductFilter, Record<string, number>> | null> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.post<PartialRecord<keyof ProductFilter, Record<string, number>> | null>("/filter-counts", { filter, search, column }, config);
    return res.data ?? null;
  }
}

export const Products = new ProductsApi();

import { uuid } from "../uuid";

export type UIProductManagement = "manual" | "automatic";

export interface UIProductDTO {
  readonly tenantId: string;
  readonly id: uuid;
  readonly name: string;
  readonly management: UIProductManagement;
  readonly lastUpdated: string;
}

export class UIProduct {
  public readonly tenantId: string = this.dto.tenantId;
  public readonly id: uuid = this.dto.id;
  public readonly name: string = this.dto.name;
  public readonly management: UIProductManagement = this.dto.management;
  public readonly lastUpdated: Date = new Date(this.dto.lastUpdated);

  public constructor(private readonly dto: UIProductDTO) {  }
}

export interface FieldConfiguration {
  title: string;
  showByDefault: boolean;
  sortable: boolean;
  filterable: boolean;
}
export const ProductFieldConfigs: PartialRecord<keyof UIProduct, FieldConfiguration> = {
  name: {
    title: "Name",
    showByDefault: true,
    sortable: true,
    filterable: false
  },
  management: {
    title: "Management",
    showByDefault: true,
    sortable: true,
    filterable: true
  },
  lastUpdated: {
    title: "Last Updated",
    showByDefault: true,
    sortable: true,
    filterable: false
  }
} as const;
export type ProductHeaderType = keyof typeof ProductFieldConfigs;

/* Filtering */
const filterFields = Object.entries(ProductFieldConfigs)
  .filter(([, config]) => config.filterable)
  .map(([name, ]) => name);
export type ProductFilterField = typeof filterFields[number];
export interface ProductFilter {
  management: UIProductManagement[];
}
export function isProductFilterField(key: unknown): key is keyof ProductFilterField {
  return typeof key === "string" && filterFields.includes(key as keyof ProductFilter);
}
export const defaultProductFilters = {
  management: [] as UIProductManagement[]
} as const satisfies ProductFilter;

/* Sorting */
type SortType = "asc" | "desc";
export interface ProductSort {
  name: SortType;
  management: SortType;
  lastUpdated: SortType;
}
export type ProductSortField = keyof ProductSort;
const sortFields: ProductSortField[] = [
  "name",
  "management",
  "lastUpdated"
];
export function isProductSortField(key: unknown): key is keyof ProductSortField {
  return typeof key === "string" && sortFields.includes(key as keyof ProductSort);
}

/**
 * This is a helper object to define the scope of a request. This is violates DRY, since the same information is already defined in the `Scope` enum in control-plane,
 * but until we move everything to shared, this is the quickest way to get the information, and have type safety in the frontend.
 * @note This is not a complete list of scopes, only the ones that are used in the frontend.
 * @note When you add a scope that is needed in the frontend, you must add it here as well.
 */

export enum Scope {
  api_keys_rw = "api_keys_rw",
  arnica_admin = "arnica_admin",
  authenticated = "authenticated",
  billing_rw = "billing_rw",
  finding_ro = "finding_ro",
  finding_rw = "finding_rw",
  finding_single_ro = "finding_single_ro",
  finding_single_status_rw = "finding_single_status_rw",
  git_posture_ro = "git_posture_ro",
  insights_ro = "insights_ro",
  integrations_ro = "integrations_ro",
  integrations_rw = "integrations_rw",
  inventory_ro = "inventory_ro",
  issues_rw = "issues_rw",
  jobs_ro = "jobs_ro",
  jobs_rw = "jobs_rw",
  magic_link_findings_rw = "magic_link_findings_rw",
  notifications_ro = "notifications_ro",
  policies_rw = "policies_rw",
  registered = "registered",
  report_ro = "report_ro",
  repository_findings_ro = "repository_findings_ro",
  repository_single_finding_ro = "repository_single_finding_ro",
  repository_single_finding_rw = "repository_single_finding_rw",
  tasks_ro = "tasks_ro",
  tasks_rw = "tasks_rw",
  user_findings_ro = "user_findings_ro",
  user_ro = "user_ro",
  user_rw = "user_rw",
  user_single_finding_ro = "user_single_finding_ro",
  user_single_finding_rw = "user_single_finding_rw"
}

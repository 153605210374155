import { RouteConfig } from "vue-router";
import { Scope } from "@/interfaces/scope";

export const jobsV2Routes: RouteConfig[] = [
  {
    path: "jobs-v2",
    component: () => import("@/pages/admin/jobs-v2/jobs-v2-page.vue"),
    meta: {
      scopes(scopes) {
        return !!(scopes.jobs_ro || scopes.tasks_ro);
      },
      sidebar: false,
      title: "Tasks",
      icon: "mdi-factory",
      frontIcon: {
        icon: "mdi-beta",
        tooltip: "Jobs v2"
      }
    },
    children: [
      {
        path: "/",
        name: "admin-jobs-v2",
        redirect: { name: "admin-jobs-v2-tasks" },
        meta: {
          scopes: [],
          sidebar: false
        }
      },
      {
        path: "tasks",
        name: "admin-jobs-v2-tasks",
        component: () => import("@/pages/admin/jobs-v2/layouts/tasks-layout-swimlane/tasks-layout-swimlane.vue"),
        meta: {
          scopes: [Scope.tasks_ro],
          sidebar: false,
          icon: "mdi-factory",
        }
      },
      {
        path: "jobs",
        name: "admin-jobs-v2-jobs",
        component: () => import("@/pages/admin/jobs-v2/layouts/jobs-layout-default/jobs-layout-default.vue"),
        meta: {
          scopes: [Scope.jobs_ro],
          sidebar: false,
          icon: "mdi-factory",
          title: "Jobs"
        }
      },
      {
        path: "tree",
        name: "admin-jobs-v2-tree",
        component: () => import("@/pages/admin/jobs-v2/layouts/tree-layout/tree-layout.vue"),
        meta: {
          scopes: [Scope.jobs_ro, Scope.tasks_ro],
          sidebar: false,
          icon: "mdi-factory",
          title: "Tree"
        }
      }
    ]
  }
];
